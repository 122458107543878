// Your variable overrides
$amaranth: #f50537;
$peppermint: #f1faee;
$aqua-island: #a8dadc;
$wedgewood: #4cae4f;
$cello: #ff9100;
$shark: #0a090c;
$silver-tree: #52b788;

$custom-colors: (
  "amaranth": $amaranth,
  "peppermint": $peppermint,
  "aqua-island": $aqua-island,
  "cello": $cello,
  "shark": $shark,
  "silver-tree": $silver-tree,
);

// set new colors
$primary: $wedgewood;
$danger: $amaranth;
$info: $cello;

$theme-colors: (
  "primary": $primary,
  "danger": $danger,
  "info": $info,
  "success": $silver-tree,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

// borders
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$border-radius-pill: 0;

// remove the default focus from bootstrap
$btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-btn-focus-color-opacity: 0;

$enable-negative-margins: true;

// Import bootstrap latest
// @import 'bootstrap';
@import "~bootstrap/scss/bootstrap.scss";
