* {
  font-size: 18px;
  line-height: 1.428;
}

.logo {
  width: 150px;
  height: auto;
}

.shadow {
  box-shadow: 5px 5px #000000;
}

.icon-sm {
  width: 18px;
}

.text-md {
  width: 30px;
}
